<template>
    <v-container fluid>
  
      <v-row>        
        <v-col
          cols="12"
        >
          <v-card
            dark
            outlined
          >
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
              Event Tags
              <v-spacer/>
            </v-card-title>
            <v-card-text class="pa-0">

              <v-data-table
                :headers="headers"
                :items="eventTags.slice(0, 10)"
                single-select
                class="elevation-5"
              >
              <!-- hide-default-footer -->
              <template v-slot:item.StallHolderName="{ item }">
                  {{ item.StallHolderName ? item.StallHolderName : 'No data available' }}
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                <v-row>
                    <v-col cols="8" class="mt-4">
                    </v-col>
                </v-row>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                    dark
                  >
                  
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add Event Tag
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                         <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >
                          <!-- <v-row>
                            <v-col cols="12"> -->
                            <v-row>
                              <v-col cols="12">
                                  <label><B>Event :-</B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
                              </v-col>
                            </v-row>

                            <v-text-field
                            v-model="editedItem.TagName"
                            label="Tag Name"
                            :rules="[v => !!v || 'Tag Name is required']"
                            ></v-text-field>

                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog dark v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete Tag ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No tags for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({

      dialog: false,
      dialogDelete: false,

      headers: [
        { text: "Tag Name", value: "TagName"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      eventTags: [],
      selectedAlleventTags: [],

      editedIndex: -1,
      editedItem: {
        EventId: '',
        TagName: ''
      },
      defaultItem: {
        EventId: '',
        TagName: ''
      },
  
      valid: true
    }),
  
    computed: {
      ...mapState('filter', ['globalEventFilter', 'allEvents', 'allStallHolders']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Tag' : 'Edit Tag'
      },
      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED MAKE API REQUEST", value)
        }
      },
      events() {
        return this.allEvents.map((obj) => {
          return {text: obj.EventName, value: obj.EventId}
        });
      }
    },
  
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      selectedEvent (obj) {
        console.log(obj)
        localStorage.setItem('setSelectedEventId', obj.EventId);
        this.getAllTags()
      }
    },
  
    created() {
      this.getAllTags()
    },
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter', 'setAllEvents', 'setFilterStallHolders']),
      ...mapActions('admin', ['getProducts', 'createCategories']),
      getAllTags() {

        let eventId = localStorage.getItem('setSelectedEventId')
        let StallholderId = localStorage.getItem('setSelectedStallholderId')

        let paramObj = {}
            paramObj.EventId = eventId;
            paramObj.StallholderId = StallholderId;

            console.log("paramObj")
            console.log(paramObj)

        this.getProducts(paramObj).then(response => {
          console.log("response")
          console.log(response)
          this.eventTags = response.EventTags;
          this.selectedAlleventTags = response.EventTags;
        })
      },

      editItem (item) {
        this.editedIndex = this.eventTags.indexOf(item)
        this.editedItem.EventId = item.EventId;
        this.editedItem.TagId = item.TagId;
        this.editedItem.TagName = item.TagName;

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.allStallHolders.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.allStallHolders.splice(this.editedIndex, 1)
        
        try {
              let event_item = [
                  {
                      Type: "tag",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createCategories(submitEvent).then(response => {
                  console.log("response")
                  console.log(response)

                  iziToast.success({
                      title: 'Event Tag',
                      message: 'Successfully delete record!',
                      position: 'topRight'
                  });

                  this.getAllTags()

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Event Tag',
                      message: 'Fail to delete record!',
                      position: 'topRight'
                  });
              })
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  
      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(this.allStallHolders[this.editedIndex], this.editedItem)
          } else {
            this.allStallHolders.push(this.editedItem)
          }

          try {
            if (this.$refs.form.validate()) {
                this.editedItem.EventId = this.selectedEvent.EventId;
                let event_item = [
                    {
                        Type: "tag",
                        Action: this.editedIndex === -1 ? 'Create' : 'Edit',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }
                console.log("submitEvent")
                console.log(submitEvent)
                this.createCategories(submitEvent).then(response => {
                    console.log("response")
                    console.log(response)

                    iziToast.success({
                        title: 'Event Tag',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                    this.getAllTags()

                }).catch(err => {
                    console.log("err")
                    console.log(err)

                    iziToast.warning({
                        title: 'Event Tag',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
          this.close()
        }
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>